<template>
  <AppModal
    name="customer-info"
    size="s"
    :closable="false"
    :simple="true"
    :loading="loading"
    @closed="onClosed"
  >
    <div class="customer-info">
      <div class="customer-info__heading">
        Let's create
        <strong>
          The best <br />
          experience
        </strong>
        for you //
      </div>

      <form @submit.prevent="submit" class="customer-info__form">
        <div class="customer-info__row">
          <InputSelect
            :inline="true"
            label="I'm here for"
            :options="targetOptions"
            :show-errors="submitted"
            :errors="validations.errors.target"
            v-model="form.target"
          />
        </div>

        <div class="customer-info__row">
          <InputSelect
            :inline="true"
            label="I'm located in"
            :options="statesOptions"
            :show-errors="submitted"
            :errors="validations.errors.state"
            v-model="form.state"
          />
        </div>

        <div v-if="hasPosition" class="customer-info__row">
          <InputSelect
            :inline="true"
            label="I'm a"
            :options="positionOptions"
            :show-errors="submitted"
            :errors="validations.errors.position"
            v-model="form.position"
          />
        </div>

        <div v-if="hasIndustry" class="customer-info__row">
          <InputSelect
            :inline="true"
            label="I'm in the"
            :options="industryOptionsFormatted"
            :show-errors="submitted"
            :errors="validations.errors.industry"
            v-model="form.industry"
          />
        </div>

        <div class="customer-info__actions">
          <ButtonPrimary>
            Submit
          </ButtonPrimary>
          <a href="#" class="link" @click="$vfm.hide('customer-info')">
            No, thanks
          </a>
        </div>
      </form>
    </div>
  </AppModal>
</template>

<script>
import { required } from '@/core/plugins/validator/validators'
import { sendAccountAnswers } from '@/api/commonApi'
import { getErrorMessage } from '@/core/helpers/error'
import states from '@/core/factories/states'

import AppModal from '@/core/components/modals/AppModal'
import InputSelect from '@/core/components/inputs/InputSelect'
import ButtonPrimary from '@/core/components/buttons/ButtonPrimary'

const validators = {
  target: [required()],
  state: [required()],
  position: [required()],
  industry: [required()],
}

export default {
  name: 'CustomerInfoModal',
  components: {
    AppModal,
    InputSelect,
    ButtonPrimary,
  },
  data() {
    return {
      loading: false,
      submitted: false,
      errorMessage: '',
      form: {
        target: '',
        position: '',
        industry: '',
      },
      targetOptions: [
        {
          title: 'Work',
          value: 'Work',
        },
        {
          title: 'Personal',
          value: 'Personal',
        },
        {
          title: 'School',
          value: 'School',
        },
      ],
      statesOptions: [
        ...states(),
        { title: 'Canada', value: 'Canada' },
        { title: 'Outside USA or Canada', value: 'Outside USA or Canada' },
      ],
      industryOptions: [
        {
          title: 'Aerospace',
          value: 'Aerospace',
        },
        {
          title: 'Automation',
          value: 'Automation',
        },
        {
          title: 'Automotive',
          value: 'Automotive',
        },
        {
          title: 'Consumer',
          value: 'Consumer',
        },
        {
          title: 'Design & Engineering Services',
          value: 'Design & Engineering Services',
        },
        {
          title: 'Education',
          value: 'Education',
        },
        {
          title: 'Electronics',
          value: 'Electronics',
        },
        {
          title: 'Energy',
          value: 'Energy',
        },
        {
          title: 'Government & Defense',
          value: 'Government & Defense',
        },
        {
          title: 'Industrial',
          value: 'Industrial',
        },
        {
          title: 'Marine & Powersports',
          value: 'Marine & Powersports',
        },
        {
          title: 'Medical',
          value: 'Medical',
        },
        {
          title: 'Other',
          value: 'Other',
        },
        {
          title: 'Service Bureau',
          value: 'Service Bureau',
        },
        {
          title: 'Sporting Goods',
          value: 'Sporting Goods',
        },
        {
          title: 'Technology',
          value: 'Technology',
        },
        {
          title: 'Test & Measurement',
          value: 'Test & Measurement',
        },
      ],
      budgetOptions: [
        {
          title: 'Under $150',
          value: 'Under $150',
        },
        {
          title: '$150 to $500',
          value: '$150 to $500',
        },
        {
          title: '$500 to $1,000',
          value: '$500 to $1,000',
        },
        {
          title: 'Over $1,000',
          value: 'Over $1,000',
        },
      ],
    }
  },
  computed: {
    payload() {
      const data = {
        target: this.form.target,
        state: this.form.state,
      }

      if (this.hasPosition) {
        data.position = this.form.position
      }

      if (this.hasIndustry) {
        data.industry = this.form.industry
      }

      return data
    },
    validations() {
      return this.$validator.validate(this.payload, validators)
    },
    hasPosition() {
      return this.form.target === 'Work' || this.form.target === 'School'
    },
    hasIndustry() {
      return this.form.target === 'Work'
    },
    hasCurrentTarget() {
      return this.form.target === 'Work'
    },
    positionOptions() {
      if (this.form.target === 'Work') {
        return [
          {
            title: 'C-Level',
            value: 'C-Level',
          },
          {
            title: 'President',
            value: 'President',
          },
          {
            title: 'Vice President',
            value: 'Vice President',
          },
          {
            title: 'Director',
            value: 'Director',
          },
          {
            title: 'Manager',
            value: 'Manager',
          },
          {
            title: 'Buyer / Purchasing',
            value: 'Buyer / Purchasing',
          },
          {
            title: 'Engineer',
            value: 'Engineer',
          },
          {
            title: 'Industrial Designer',
            value: 'Industrial Designer',
          },
          {
            title: 'R&D',
            value: 'R&D',
          },
          {
            title: 'Sales',
            value: 'Sales',
          },
          {
            title: 'Finance',
            value: 'Finance',
          },
          {
            title: 'Project Manager',
            value: 'Project Manager',
          },
          {
            title: 'Owner / Entrepreneur',
            value: 'Owner / Entrepreneur',
          },
        ]
      } else if (this.form.target === 'School') {
        return [
          {
            title: 'Professor',
            value: 'Professor',
          },
          {
            title: 'Student',
            value: 'Student',
          },
          {
            title: 'Other Staff',
            value: 'Other Staff',
          },
        ]
      } else {
        return []
      }
    },
    industryOptionsFormatted() {
      const result = [...this.industryOptions]

      result.sort((a, b) => {
        if (a.value < b.value) {
          return -1
        }
        if (a.value > b.value) {
          return 1
        }
        return 0
      })

      return result
    },
  },
  methods: {
    onClosed() {
      this.loading = false
      this.submitted = false
      this.errorMessage = ''
      this.form = {
        target: '',
        state: '',
        position: '',
        industry: '',
      }
    },
    async submit() {
      this.submitted = true

      if (!this.validations.isValid) return

      try {
        this.loading = true

        await sendAccountAnswers(this.payload)

        this.$vfm.hide('customer-info')
      } catch (err) {
        this.errorMessage = getErrorMessage(err)
      } finally {
        this.loading = false
      }
    },
  },
}
</script>
